import React from 'react';
import { Tooltip, Typography, Box } from '@mui/material';
import { InfoOutlined, TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { ReactComponent as NoData } from 'assets/NotEnoughData.svg';


function ThirtyDayChange() {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: '12px', padding: '16px' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* Title */}
      <Typography variant='h6' sx={{ fontWeight: 600 }}>
        Recent trend
      </Typography>
      <Typography variant='body2' sx={{ mt: 1 }}>
        A comparison of the patient’s most recent mood log with the previous one.
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <TrendingUp sx={{ color: '#4A7F4B' }} />
        <Typography variant='body2'>
          An improvement of 2 or more in any mood vector, without significant declines in others
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <TrendingFlat sx={{ color: '#787878' }} />
        <Typography variant='body2'>
          A change of no more than 1 across all mood vectors
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <TrendingDown sx={{ color: '#CC3B3B' }} />
        <Typography variant='body2'>
          A decline of 2 or more in any mood vector
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <NoData width={20} height={20} />
        <Typography variant='body2'>
          Not enough data to determine a change
        </Typography>
      </Box>
    </Box>
  );
}


function InfoIconToolTip() {
  return (
    <Tooltip
      title={(
        <ThirtyDayChange />
      )}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#F2F2F2', // Tooltip background color
            color: 'black', // Tooltip text color
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional shadow
            borderRadius: '4px', // Rounded corners
            maxWidth: '900px', // Optional: tooltip width
            padding: '4px 16px', // Padding for tooltip content
            height: 'auto', // Adjust height based on content
            whiteSpace: 'nowrap', // Prevent text wrapping
          },
        },
        arrow: {
          sx: {
            color: '#F2F2F2', // Arrow color matching tooltip background
          },
        },
      }}
      placement='top'
      arrow
    >
      <span>
        {/* The icon that will display the tooltip on hover */}
        <InfoOutlined color='primary' sx={{ cursor: 'pointer', mt: 1, ml: 1, fontSize: 18 }} />
      </span>
    </Tooltip>
  );
}

export default InfoIconToolTip;
