import { parse, compareAsc, format, sub, add, startOfMonth } from 'date-fns';
import {
  Mood,
  MoodOptions,
  MoodTrackingAverage,
  MoodTrackingList,
} from 'pages/Dashboard/types/moods.types';
import {
  GroupedWaypoints,
  NestedWaypoints,
  TimeInterval,
  WaypointTracking,
  WaypointTrackingAPIResponse,
  WaypointValues,
} from 'pages/Dashboard/types/waypoints.types';
import Theme from 'theme';
import { formatToBrowserDate } from 'utils/dateUtils';


export const GAUGE_MAXIMUM = 10;

export enum TimeFrameOptions {
  LAST_7_DAYS = '7 days',
  LAST_30_DAYS = '30 days',
  LAST_3_MONTHS = '3 months',
  LAST_6_MONTHS = '6 months',
}

export enum ArrowTypes {
  SINGLE_UP = 'Single up',
  SINGLE_DOWN = 'Single down',
  DOUBLE_UP = 'Double up',
  DOUBLE_DOWN = 'Double down',
}

export const defaultMoodTracking = {
  moods: [],
};

export const defaultWaypointTracking: WaypointTrackingAPIResponse = {
  waypoints: [],
  averages: [],
  absoluteAvgValue: 0,
  absoluteValueUnit: '',
  previousIntervalAverages: [],
};

export function getNestedWaypointsListFromFlatList(waypoints: WaypointTracking[]) {
  const nodeMap: { [key: string]: NestedWaypoints } = {};
  const roots: NestedWaypoints[] = [];

  waypoints.forEach((waypoint) => {
    const waypointObj = waypoint;
    nodeMap[waypointObj.waypoint.id] = {
      waypoint: { ...waypointObj.waypoint },
      value: waypoint.value,
    };
  });

  waypoints.forEach((waypoint) => {
    const waypointObj = waypoint.waypoint;
    const currentWaypoint = nodeMap[waypointObj.id];
    const parentTreeNode = nodeMap[waypointObj.parentWaypoint?.id as string];
    if (parentTreeNode) {
      // children are the subwaypoints
      if (!parentTreeNode.waypoint.children) {
        parentTreeNode.waypoint.children = [];
      }
      parentTreeNode.waypoint.children.push(currentWaypoint);
    }
    else {
      roots.push(currentWaypoint);
    }
  });

  return roots;
}

export function getWaypointDataFromId(waypoints: WaypointTracking[], waypointId: string) {
  return waypoints.find((w) => w.waypoint.id === waypointId);
}

export function getFilteredWaypointsData(
  currentWaypoint: WaypointTracking[],
  previousWaypoint: WaypointTracking[],
) {
  const allWaypoints: WaypointTracking[] = [];
  currentWaypoint.forEach((w) => {
    allWaypoints.push({ ...w });
  });
  previousWaypoint.forEach((w) => {
    const isIncludedInAllWaypoints = allWaypoints.some(
      (currentWp) => currentWp.waypoint.id === w.waypoint.id,
    );
    if (isIncludedInAllWaypoints) {
      return null;
    }
    allWaypoints.push({ ...w });
    return null;
  });

  const allWaypointsNestedList = getNestedWaypointsListFromFlatList(allWaypoints);
  const grpAll = groupWaypointsByCategory(allWaypointsNestedList);
  return grpAll;
}

export function groupWaypointsByCategory(waypoints: NestedWaypoints[]) {
  const waypointsData: GroupedWaypoints = {};

  waypoints.forEach((data) => {
    const {
      waypoint: { category: waypointCategory },
    } = data;
    const category = waypointCategory.toLowerCase();

    if (Object.hasOwn(waypointsData, category.toLowerCase())) {
      waypointsData[category.toLowerCase()] = [...waypointsData[category.toLowerCase()], data];
    }
    else {
      waypointsData[category] = [data];
    }
  });
  return waypointsData;
}

export function getWaypointColorByIndex(index: number) {
  // Get the index within the valid range of indices
  const cycleIndex = index % Theme.custom.waypoints.length;
  return Theme.custom.waypoints[cycleIndex];
}

export function getArrows(currentLabel: string, prevLabel: string) {
  const trackingOrder = ['less', 'typical', 'more'];
  const currentLabelValue = trackingOrder.indexOf(currentLabel);
  const prevLabelValue = trackingOrder.indexOf(prevLabel);
  if (currentLabelValue < 0 || prevLabelValue < 0) {
    return null;
  }
  const orderDifference = currentLabelValue - prevLabelValue;
  if (orderDifference > 0) {
    if (orderDifference === 1) {
      return 'Single up';
    }

    if (orderDifference === 2) {
      return 'Double up';
    }
  }
  else {
    if (Math.abs(orderDifference) === 1) {
      return 'Single down';
    }
    if (Math.abs(orderDifference) === 2) {
      return 'Double down';
    }
  }
  return null;
}

function getLabelFormattedText(label: string, waypointName?: string) {
  if (waypointName === 'quality') {
    switch (label) {
      case 'less': return 'lower';
      case 'more': return 'higher';
      default: return label;
    }
  }

  if (waypointName === 'personal' || waypointName === 'work') {
    switch (label) {
      case 'less': return 'worse';
      case 'more': return 'better';
      default: return label;
    }
  }
  return label;
}

export function getWaypointFormattedValues(value: WaypointValues, waypointName?: string) {
  const roundedOffValue = Math.round(value / 10) * 10;
  const labelMap: { [key: number]: string } = {
    10: 'less',
    20: 'typical',
    30: 'more',
  };
  if (!value) {
    return 'No data';
  }
  if (value < 10) {
    return getLabelFormattedText('less', waypointName);
  }
  if (value > 30) {
    return getLabelFormattedText('more', waypointName);
  }
  const label = labelMap?.[roundedOffValue];
  if (!label) {
    return 'No data';
  }
  return getLabelFormattedText(label, waypointName);
}

const moodOptions: MoodOptions[] = [
  {
    mood: Mood.MOOD_HAPPINESS,
    currentValue: 0,
    previousValue: 0,
    label: 'Happiness',
  },
  {
    mood: Mood.MOOD_MOTIVATION,
    currentValue: 0,
    previousValue: 0,
    label: 'Motivation',
  },
  {
    mood: Mood.MOOD_ANXIOUSNESS,
    currentValue: 0,
    previousValue: 0,
    label: 'Anxiousness',
  },
];

export function getMoodOptions(
  currMoodData: MoodTrackingAverage[],
  previousMoodData: MoodTrackingAverage[],
) {
  const currentMoodDataMap: { [key: string]: number } = currMoodData.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.mood]: curr.value,
    }),
    {},
  );
  const previousMoodDataMap: { [key: string]: number } = previousMoodData.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.mood]: curr.value,
    }),
    {},
  );
  return moodOptions.map((mood) => ({
    mood: mood.mood,
    currentValue: currentMoodDataMap[mood.mood] || 0,
    previousValue: previousMoodDataMap[mood.mood] || 0,
    label: mood.label,
  }));
}

function getInterval(previous: Date, presentDay: Date, previousToLast: Date, datePrior: Date) {
  return {
    currInterval: {
      start: formatToBrowserDate(previous),
      end: formatToBrowserDate(presentDay),
    },
    previousInterval: {
      start: formatToBrowserDate(previousToLast),
      end: formatToBrowserDate(datePrior),
    },
    current: {
      start: new Date(previous).setHours(0, 0, 0, 0),
      end: new Date(presentDay).setHours(0, 0, 0, 0),
    },
    previous: {
      start: new Date(previousToLast).setHours(0, 0, 0, 0),
      end: new Date(datePrior).setHours(0, 0, 0, 0),
    },
  };
}

export function getDuration(timeFrame: string) {
  switch (timeFrame) {
    case TimeFrameOptions.LAST_30_DAYS: {
      return 'DAY';
    }
    case TimeFrameOptions.LAST_3_MONTHS: {
      return 'MONTH';
    }
    case TimeFrameOptions.LAST_6_MONTHS: {
      return 'MONTH';
    }
    default:
      return 'DAY';
  }
}

export function getTimeFrameInterval(timeFrame: string) {
  const presentDay = new Date();

  switch (timeFrame) {
    case TimeFrameOptions.LAST_7_DAYS: {
      const previous = sub(presentDay, { days: 6 });
      const datePrior = sub(previous, { days: 1 });
      const previousToLast = sub(datePrior, { days: 6 });
      return getInterval(previous, presentDay, previousToLast, datePrior);
    }
    case TimeFrameOptions.LAST_30_DAYS: {
      const previous = sub(presentDay, { days: 29 });
      const datePrior = sub(previous, { days: 1 });
      const previousToLast = sub(datePrior, { days: 29 });
      return getInterval(previous, presentDay, previousToLast, datePrior);
    }
    case TimeFrameOptions.LAST_3_MONTHS: {
      const previous = startOfMonth(sub(presentDay, { months: 2 }));
      const datePrior = sub(previous, { days: 1 });
      const previousToLast = startOfMonth(sub(datePrior, { months: 2 }));
      return getInterval(previous, presentDay, previousToLast, datePrior);
    }
    case TimeFrameOptions.LAST_6_MONTHS: {
      const previous = startOfMonth(sub(presentDay, { months: 5 }));
      const datePrior = sub(previous, { days: 1 });
      const previousToLast = startOfMonth(sub(datePrior, { months: 5 }));
      return getInterval(previous, presentDay, previousToLast, datePrior);
    }
    default:
      return getInterval(presentDay, presentDay, presentDay, presentDay);
  }
}

export function getFormattedChartData(chartData: MoodTrackingList[]) {
  const sortedByDate = chartData.sort((data1, data2) => {
    const parsedDateA = parse(`${data1.date.year}-${data1.date.month}-${data1.date.day}`, 'yyyy-MM-dd', new Date());
    const parsedDateB = parse(`${data2.date.year}-${data2.date.month}-${data2.date.day}`, 'yyyy-MM-dd', new Date());
    return compareAsc(parsedDateA, parsedDateB);
  });

  return sortedByDate.map((data) => {
    const { date } = data;
    return {
      value: data.value,
      date: `${date.month}/${date.day}`,
    };
  });
}

export function getGaugeData(current: number) {
  return [{ value: current }, { value: GAUGE_MAXIMUM - current }];
}

export function getGaugeColor(mood: Mood, moodValue: number) {
  const isAnxiousness = mood === Mood.MOOD_ANXIOUSNESS;

  if (isAnxiousness) {
    if (moodValue >= 0 && moodValue <= 2) {
      return 'green';
    }
    else if (moodValue >= 3 && moodValue <= 6) {
      return 'orange';
    }
    else return 'red';
  }

  if (moodValue >= 0 && moodValue <= 3) {
    return 'red';
  }
  else if (moodValue >= 4 && moodValue <= 7) {
    return 'orange';
  }
  else return 'green';
}

export function formatIntervalDate(interval: TimeInterval) {
  let dateFormat = 'LLL d';
  const currYear = format(new Date(), 'yyyy');
  const { start: intervalStartDate, end: intervalEndDate } = interval;

  const startYear = format(new Date(intervalStartDate), 'yyyy');
  const endYear = format(new Date(intervalEndDate), 'yyyy');

  const hasYearChanged = startYear !== currYear || endYear !== currYear;

  if (hasYearChanged) dateFormat = 'LLL d yyyy';

  return `${format(new Date(intervalStartDate), dateFormat)} - ${format(
    new Date(intervalEndDate),
    dateFormat,
  )}`;
}

const barSizeMap = {
  [TimeFrameOptions.LAST_7_DAYS]: 56,
  [TimeFrameOptions.LAST_30_DAYS]: 14,
  [TimeFrameOptions.LAST_3_MONTHS]: 111,
  [TimeFrameOptions.LAST_6_MONTHS]: 64,
};

export function getBarProps(timeFrame: string) {
  const barProps = {
    barSize: barSizeMap[timeFrame as TimeFrameOptions],
  };

  return barProps;
}


const averageTextMap = {
  [TimeFrameOptions.LAST_7_DAYS]: '7-day average',
  [TimeFrameOptions.LAST_30_DAYS]: '30-day average',
  [TimeFrameOptions.LAST_3_MONTHS]: '3-month average',
  [TimeFrameOptions.LAST_6_MONTHS]: '6-month average',
};

export function getAverageText(timeFrame: string, hasMultipleItems?: boolean) {
  return `${averageTextMap[timeFrame as TimeFrameOptions]}${
    hasMultipleItems ? 's' : ''
  }`;
}

const timeFrameMap = {
  [TimeFrameOptions.LAST_7_DAYS]: 1,
  [TimeFrameOptions.LAST_30_DAYS]: 7,
  [TimeFrameOptions.LAST_3_MONTHS]: 1,
  [TimeFrameOptions.LAST_6_MONTHS]: 1,
};

const numberOfTicks = {
  [TimeFrameOptions.LAST_7_DAYS]: 7,
  [TimeFrameOptions.LAST_30_DAYS]: 4,
  [TimeFrameOptions.LAST_3_MONTHS]: 3,
  [TimeFrameOptions.LAST_6_MONTHS]: 6,
};


export const getTickFormat = (date: number, isInDays: boolean, dateFormat = 'M/d') => ({
  day: isInDays ? format(date, 'E') : '',
  date: isInDays ? format(date, dateFormat) : format(date, 'MMM'),
  year: format(date, 'uuuu'),
});

export const getChartDateRanges = (timeFrame: TimeFrameOptions) => {
  const timeInterval = getTimeFrameInterval(timeFrame);
  const { start, end } = timeInterval.current;

  const isInDays = [
    TimeFrameOptions.LAST_7_DAYS,
    TimeFrameOptions.LAST_30_DAYS,
  ].includes(timeFrame);
  const timeFrameIn = isInDays ? 'days' : 'months';
  const endTime = new Date(end).getTime();
  const startTime = new Date(start).getTime();
  const diffInDays = isInDays
    ? (endTime - startTime) / (1000 * 60 * 60 * 24)
    : numberOfTicks[timeFrame];
  const addNumberOfEntries = isInDays ? 0 : 1;

  if (TimeFrameOptions.LAST_30_DAYS === timeFrame) {
    const sundays = [];
    const s = new Date(startTime);
    const e = new Date(endTime);

    while (s <= e) {
      if (s.getDay() === 0) {
        sundays.push(s.getTime());
      }
      s.setDate(s.getDate() + 1);
    }

    return {
      ticks: sundays.reverse(),
      startTime: sub(new Date(startTime), { hours: 12 }).getTime(),
      endTime: add(new Date(endTime), { hours: 12 }).getTime(),
      isInDays,
    };
  }

  const ticks = Array.from({ length: numberOfTicks[timeFrame] }, (_, i) => sub(
    endTime,
    { [timeFrameIn]: diffInDays
    - (i + addNumberOfEntries) * timeFrameMap[timeFrame] },
  ).getTime());

  return {
    ticks,
    startTime: sub(new Date(startTime), { [isInDays ? 'hours' : 'days']: isInDays ? 12 : 15 }).getTime(),
    endTime: add(new Date(endTime), { [isInDays ? 'hours' : 'days']: isInDays ? 12 : 15 }).getTime(),
    isInDays,
  };
};
