import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { MoodColorType, MoodOptions, MoodTrackingList } from 'pages/Dashboard/types/moods.types';
import {
  TimeFrameOptions,
  getFormattedChartData,
  getGaugeColor,
  getGaugeData,
} from 'pages/Dashboard/utils/trackingUtils';
import GaugeChart from 'pages/Dashboard/components/GaugeChart';
import theme from 'theme';
import SingleLineChart from 'pages/Dashboard/components/SingleLineChart';
import ContentLoader from 'components/Common/ContentLoader';

const averageTextMap = {
  [TimeFrameOptions.LAST_7_DAYS]: '7-day average',
  [TimeFrameOptions.LAST_30_DAYS]: '30-day average',
  [TimeFrameOptions.LAST_3_MONTHS]: '3-month average',
  [TimeFrameOptions.LAST_6_MONTHS]: '6-month average',
};

const MoodColors: MoodColorType = {
  Happiness: { positive: 'green', negative: 'red' },
  Motivation: { positive: 'green', negative: 'red' },
  Anxiousness: { positive: 'red', negative: 'green' },
};

interface MoodCardProps {
  timeFrame: string;
  cardData: MoodOptions;
  chartData: MoodTrackingList[];
  noData: boolean;
  isMoodLoading: boolean
}

export default function MoodCard({
  timeFrame,
  cardData,
  chartData,
  noData,
  isMoodLoading,
}: MoodCardProps): JSX.Element {
  const moodDifference = cardData.currentValue - cardData.previousValue;
  const formattedChartData = getFormattedChartData(chartData);

  const renderComparison = () => {
    const isPositive = moodDifference > 0;
    const isZero = moodDifference === 0;

    if (isZero) {
      return (
        <Typography color={theme.custom.colors.lightTextSecondary}>
          <Typography component='span' fontWeight={700}>
            no change
          </Typography>
          {` vs. prior ${timeFrame}`}
        </Typography>
      );
    }
    const color = isPositive
      ? MoodColors[cardData.label].positive
      : MoodColors[cardData.label].negative;
    return (
      <>
        {isPositive ? (
          <TrendingUp sx={{ color: `${color}` }} />
        ) : (
          <TrendingDown sx={{ color: `${color}` }} />
        )}
        <Typography color={theme.custom.colors.lightTextSecondary} ml={1}>
          <Typography component='span' color={color} fontWeight={700}>
            {isPositive ? `+${moodDifference}` : moodDifference}
          </Typography>
          {` vs. prior ${timeFrame}`}
        </Typography>
      </>
    );
  };

  return (
    <Grid item xs={12} md={4} key='mood-card'>
      <Card sx={{ height: '100%',
        '& .MuiCardContent-root:last-child': {
          paddingBottom: 2,
        } }}
      >
        <CardHeader sx={{ pb: 0 }} title={cardData.label} />
        <ContentLoader
          isFetching={isMoodLoading}
          isError={false}
        >
          <CardContent style={noData ? { paddingTop: 0 } : {}}>
            {noData ? <Typography>No data exists for the selected timeframe</Typography>
              : (
                <Stack>
                  <Box width='100%' textAlign='center'>
                    <GaugeChart
                      color={getGaugeColor(cardData.mood, cardData.currentValue)}
                      data={getGaugeData(cardData.currentValue)}
                      value={cardData.currentValue.toString()}
                    />
                  </Box>
                  <Box display='flex' flexDirection='column' alignItems='center' mb={3}>
                    <Typography color={theme.custom.colors.lightTextSecondary} mb={1}>
                      {averageTextMap[timeFrame as TimeFrameOptions]}
                    </Typography>
                    <Box display='flex'>{renderComparison()}</Box>
                  </Box>
                  <Box width='100%' textAlign='left'>
                    <Typography color='gray' fontSize={14}>
                      past
                      {' '}
                      {timeFrame}
                    </Typography>
                    <SingleLineChart data={formattedChartData} dataKeyX='date' dataKeyLine='value' />
                  </Box>
                </Stack>
              )}
          </CardContent>
        </ContentLoader>
      </Card>
    </Grid>
  );
}
