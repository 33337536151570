import { LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, ResponsiveContainer } from 'recharts';
import * as React from 'react';

interface LineChartComponentProps {
    data: { [key: string]: string | number; }[],
    dataKeyX: string,
    dataKeyLine: string,
}

export default function SingleLineChart(
  {
    data,
    dataKeyX,
    dataKeyLine,
  }: LineChartComponentProps,
): JSX.Element {
  const uniqueDates = [] as { [key: string]: string | number; }[];
  data.reduce((acc, curr) => {
    if (acc[curr[dataKeyX] as number] === undefined) {
      uniqueDates.push(curr);
    }
    return {
      ...acc,
      [curr[dataKeyX] as string]: curr.value,
    };
  }, {} as { [key: string]: string | number; });

  return (
    <ResponsiveContainer width='100%' height={150}>
      <LineChart data={uniqueDates} margin={{ top: 5, bottom: 0 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey={dataKeyX} fontSize={12} height={20} />
        <YAxis
          width={20}
          axisLine={false}
          domain={[0, 10]}
          interval={0}
          fontSize={12}
          ticks={Array.from({ length: 6 }, (_, i) => i * 2)}
        />
        <Tooltip wrapperStyle={{ color: 'black' }} />
        <Line type='monotone' dataKey={dataKeyLine} />
      </LineChart>
    </ResponsiveContainer>
  );
}
