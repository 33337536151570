// eslint-disable-next-line import/no-cycle
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { ListPracticesAPIResponse } from 'types/Entitites';
import { GoogleDate, Time } from 'utils/dateUtils';
import { GooglePhoneNumber } from 'utils/phoneUtils';

export enum emotions {
  angry,
  elated,
  happy,
  humiliated,
  sad,
}

export enum activityTypes {
  cycle,
  sleep,
  swim,
  tennis,
  walk,
}

export enum lifeEventTypes {
  professional,
  personal,
}

export enum PatientRecordRetrievalStatus {
  PATIENT_RECORD_RETRIEVAL_STATUS_UNSPECIFIED = 'PATIENT_RECORD_RETRIEVAL_STATUS_UNSPECIFIED',
  PATIENT_RECORD_RETRIEVAL_STATUS_PENDING = 'PATIENT_RECORD_RETRIEVAL_STATUS_PENDING',
  PATIENT_RECORD_RETRIEVAL_STATUS_FETCHING = 'PATIENT_RECORD_RETRIEVAL_STATUS_FETCHING',
  PATIENT_RECORD_RETRIEVAL_STATUS_FETCHED = 'PATIENT_RECORD_RETRIEVAL_STATUS_FETCHED',
  PATIENT_RECORD_RETRIEVAL_STATUS_TRANSFORMING = 'PATIENT_RECORD_RETRIEVAL_STATUS_TRANSFORMING',
  PATIENT_RECORD_RETRIEVAL_STATUS_TRANSFORMED = 'PATIENT_RECORD_RETRIEVAL_STATUS_TRANSFORMED',
  PATIENT_RECORD_RETRIEVAL_STATUS_RETRIEVED = 'PATIENT_RECORD_RETRIEVAL_STATUS_RETRIEVED',
  PATIENT_RECORD_RETRIEVAL_STATUS_FAILED = 'PATIENT_RECORD_RETRIEVAL_STATUS_FAILED',
}

export interface StringBooleanIndex {
  [k: string]: boolean;
}

type WaypointPair = {
  id: string;
  name: string;
  displayName?: string;
};

export interface WayPointsIndex {
  [k: string]: Array<WaypointPair>;
}

export type Waypoints = {
  name: string;
  id: string;
  displayName?: string;
  category: string;
  creatorType?: string;
  parentWaypoint?: Waypoints;
  type?: string;
  description?: string;
};

export type CustomWaypoints = {
  name: string;
  id?: string;
  displayName?: string;
  category: string;
  creatorType?: string;
  parentWaypoint?: Waypoints;
};

export enum UserMetadataFlags {
  AddPatientPreference = 'add-patient-preference',
  SuggestScaleFeatureFlag = 'suggest_scale_feature_flag',
  NumberOfRowsPerPage = 'number_of_rows_per_page',
  DefaultSortField = 'default_sort_field',
  DefaultSortDirection = 'default_sort_direction',
  EnableNewList = 'enable_new_list',
}

export enum AddPatientPreference {
  Invite = 'invite',
  Demographic = 'demographic'
}

export type UserMetadata = {
  userId?: string;
  flag: {
    name: UserMetadataFlags;
    value?: string;
  };
};

export type UserMetadataGetPayload = {
  name: UserMetadataFlags;
  userId: string;
}

export type PatientFormData = {
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  email?: string;
  dob?: string;
  gender?: string;
  mobile?: string;
  street?: string;
  streetAdditional?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  nextAppointmentDate?: string;
  id?: string;
  practiceId?: string;
  providerId?: string;
  consent?: boolean;
};

export type AddressObj = {
  city?: string;
  country?: string;
  state?: string;
  street?: string;
  streetAdditional?: string;
  zip?: string;
};

export interface PatientHealthRecordStatusAPIResponse {
  status: {
    recordRetrievalStatus: PatientRecordRetrievalStatus;
  };
}

export type ReducerState = {
  formData: PatientFormData;
  waypoints: Waypoints[];
  patientList: PatientObj[]
};

export type PatientContextValues = {
  state: ReducerState;
  dispatch: React.Dispatch<PatientAction>;
  addingPatient: boolean;
  practicesList: ListPracticesAPIResponse | null;
};

export interface PatientInvite {
  mobile?: string;
  email?: string;
}

export type InvitePatientFormData = Required<
  Pick<PatientForm, 'patients' | 'practiceId' | 'providerId'>
>;

export type CreatePatientFormData = Required<Omit<PatientForm, 'patients'>>;

export interface PatientForm {
  firstName?: string;
  lastName?: string;
  preferredName?: string;
  email?: string;
  dob?: string;
  gender?: string;
  mobile?: string;
  street?: string;
  streetAdditional?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  nextAppointmentDate?: string;
  practiceId?: string;
  providerId?: string;
  patients?: PatientInvite[];
  consent?: boolean;
}

interface Appointment {
  date: GoogleDate;
  time: Time;
}

interface PatientAddress {
  city?: string;
  country?: string;
  state?: string;
  street?: string;
  streetAdditional?: string;
  zip?: string;
}

interface PatientName {
  firstName: string;
  lastName: string;
  middleName: string;
  preferredName: string;
}

export interface PatientPayload {
  address: PatientAddress;
  dateOfBirth: GoogleDate;
  email: string;
  gender: string;
  name: PatientName;
  phoneNumber: GooglePhoneNumber;
  consent?: boolean;
}

export interface PatientDemographicInfoPayload {
  appointment: Appointment;
  patient: PatientPayload;
  practiceId: string;
  headlampProviderId: string;
}

export interface PatientInvitesPayload {
  patient: PatientInvite[];
  practiceId: string;
  headlampProviderId: string;
}

export enum PatientContextAction {
  SET_FORM_DATA = 'SET_FORM_DATA',
  SET_WAYPOINTS = 'SET_WAYPOINTS',
  RESET_FORM_DATA = 'RESET_FORM_DATA',
  SET_PATIENT_LIST = 'SET_PATIENT_LIST',
}

export type PatientActionPayload = {
  formData?: PatientFormData;
  waypoints?: Waypoints[];
  patientList?: PatientObj[];
};

export type PatientAction = {
  type: PatientContextAction;
  payload?: PatientActionPayload;
};

export enum PatientGender {
  MALE = 'PATIENT_GENDER_MALE',
  FEMALE = 'PATIENT_GENDER_FEMALE',
}

export enum SourceType {
  SOURCE_TYPE_UNSPECIFIED = 0,
  SOURCE_TYPE_HEALTH_GORILLA = 1,
  SOURCE_TYPE_PATIENT_REPORTED = 2,
  SOURCE_TYPE_PROVIDER_REPORTED = 3,
}

export type PatientFilters = { filters: string[] };

type ResendInvitePatient = {
  id: string;
  email: string;
  phoneNumber: GooglePhoneNumber | undefined;
}

export type ResendInvitePayload = {
  patient: ResendInvitePatient[],
  headlampProviderId: string;
  practiceId: string;
}

export type ResolveInvitePayload = {
  patient: ResendInvitePatient[],
  headlampProviderId: string;
  practiceId: string;
}

export type ProviderConsent = { date: string };

export type CreateWaypointPayload = {
  waypoint: Waypoints;
}
